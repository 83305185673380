<header>
  <img src="./assets/kanoma-en-tete-2.png" alt="en tête" />

  <div class="header-content">
    <div *ngIf="displayMenu" class="header__menu">
      <button mat-icon-button [matMenuTriggerFor]="menu" class="primary" aria-label="menu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['/accueil']">Accueil</button>
        <button mat-menu-item [routerLink]="['reservations/mes-reservations']">Mes reservations</button>
        <button mat-menu-item [routerLink]="['/stats']">Statistiques</button>
      </mat-menu>
    </div>

    <div class="header__user">
      <app-header></app-header>
    </div>

    <div class="header__title">
      <h1 class="title">Kanoplan</h1>
    </div>
  </div>
</header>

<main class="container">
  <ng-content></ng-content>
</main>

<footer class="kano-font">
  <span>Kanoplan - © Kanoma {{ currentYear }} - <a href="http://kanoma.fr/">http://kanoma.fr/</a></span>
  <img src="./assets/image6.png" class="img-fluid" alt="en tête" />
</footer>
