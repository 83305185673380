import { Component, Input } from '@angular/core';

@Component({
  selector: 'main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent {
  @Input() displayMenu!: boolean;

  currentYear: number;

  constructor() {
    this.currentYear = new Date().getFullYear();
  }
}
