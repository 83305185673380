import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Reservation } from '~/shared/models/reservation.model';
import { Salle } from '~/shared/models/salle.model';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css'],
})
export class BarChartComponent implements OnChanges {
  @Input() reservations?: Reservation[] = [];
  @Input() salles?: Salle[] = [];
  @Input()
  get start() {
    return this.__start;
  }
  set start(value: Date) {
    this.__start = new Date(value);
  }
  @Input()
  get end() {
    return this.__end;
  }
  set end(value: Date) {
    this.__end = new Date(value);
  }

  private __start!: Date;
  private __end!: Date;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  isIntervalMoreThanAyear = false;

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 1,
        },
        beginAtZero: true,
        title: {
          text: "Nombre d'équipier",
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };
  public barChartType: ChartType = 'bar';

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [],
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['salles'] || changes['reservations']) {
      this.computeBarChartData();
    }
  }

  computeBarChartData() {
    if (!this.salles || !this.reservations || !this.start || !this.end) {
      return;
    }

    const nbMilliscdInADay = 86400000;
    const diffMilli = Math.abs(this.end.getTime() - this.start.getTime());
    const diffInDay = Math.ceil(diffMilli / nbMilliscdInADay);

    if (diffInDay > 356) {
      this.isIntervalMoreThanAyear = true;
      return;
    }

    this.isIntervalMoreThanAyear = false;
    const datasets = this.salles.map(s => {
      return { data: [], label: s.nom, id: s.id };
    });
    const isMonth = this.start?.getMonth() !== this.end?.getMonth();
    this.computeDatasets(datasets, isMonth);
  }

  private computeDatasets(datasets: any[], isMonth: boolean) {
    if (!this.start || !this.end) {
      return;
    }
    const labels: string[] = [];

    for (
      let date = new Date(this.start.getFullYear(), this.start.getMonth(), this.start.getDate());
      date <= this.end;
      isMonth ? date.setMonth(date.getMonth() + 1) : date.setDate(date.getDate() + 1)
    ) {
      if (!isMonth && (date.getDay() === 6 || date.getDay() === 0)) {
        // sunday or saturday
        continue;
      }
      const label = isMonth ? date.toLocaleString('default', { month: 'long' }) : date.toLocaleString().split(',')[0];
      labels.push(label);
      datasets.forEach(d => {
        let nbPerX = this.reservations
          ?.filter(r => r.salleId === d.id && (isMonth ? this.isReservationMatchMonth(r, date) : this.isReservationMatchDate(r, date)))
          .map(r => this.getOccupationperReservation(r))
          .reduce((p, c) => p + c, 0);
        if (isMonth && nbPerX) {
          nbPerX = nbPerX / this.getNbOpenDayPerMonth(date); // in the month case we display the average
        }
        d.data.push(nbPerX);
      });
      this.barChartData = {
        labels: labels,
        datasets: datasets,
      };
    }
  }

  private getNbOpenDayPerMonth(date: Date): number {
    let i = 0;
    for (let d = new Date(date.getFullYear(), date.getMonth(), 1); d.getMonth() === date.getMonth(); d.setDate(d.getDate() + 1)) {
      ++i;
    }
    return i;
  }

  private isReservationMatchDate(r: Reservation, date: Date) {
    return r.dateReservation.date.getDate() === date.getDate();
  }

  private isReservationMatchMonth(r: Reservation, date: Date) {
    return r.dateReservation.date.getMonth() === date.getMonth();
  }

  private getOccupationperReservation(r: Reservation) {
    const am = r.dateReservation?.matin ? 0.5 : 0;
    const pm = r.dateReservation?.apresMidi ? 0.5 : 0;
    return am + pm;
  }
}
