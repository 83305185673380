import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgChartsModule } from 'ng2-charts';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { StatPerMonthComponent } from './pages/stat-per-month/stat-per-month.component';
import { StatistiqueRoutingModule } from './statistique-routing.module';

@NgModule({
  declarations: [StatPerMonthComponent, BarChartComponent],
  imports: [
    CommonModule,
    StatistiqueRoutingModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    FormsModule,
    NgChartsModule,
  ],
})
export class StatistiqueModule {}
