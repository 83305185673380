<h1 mat-dialog-title align="center">Réserver</h1>
<mat-dialog-content>
  <form [formGroup]="formulaireReservation">
    <div>
      <span>Je réserve pour :</span>
    </div>
    <div class="mat-radio-group__wrapper">
      <mat-radio-group aria-label="Select an option" formControlName="reservationForMe" (change)="onChangeReservationForMe()">
        <mat-radio-button [value]="true">Moi</mat-radio-button>
        <mat-radio-button [value]="false">Une autre personne</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="row row_name">
      <div class="col">
        <mat-form-field>
          <mat-label>Nom</mat-label>
          <input formControlName="name" matInput placeholder="Nom" (input)="onChangeName()" />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Prénom</mat-label>
          <input formControlName="firstName" matInput placeholder="Prenom" (input)="onChangeFirstName()" />
        </mat-form-field>
      </div>
    </div>
    <div class="row row-name">
      <div class="col">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput placeholder="Email" />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Salle</mat-label>
          <input formControlName="room" matInput placeholder="Salle" />
        </mat-form-field>
      </div>
    </div>
    <div class="row row-center">
      <div class="col">
        <mat-form-field>
          <mat-label>Date réservation</mat-label>
          <input
            formControlName="date"
            matInput
            [matDatepicker]="reservationPicker"
            [matDatepickerFilter]="dateFilter"
            (input)="onChangeDate()"
            (dateChange)="onChangeDate()"
            [min]="minDate0"
            class="cy__date-reservation-input"
          />
          <mat-datepicker-toggle matSuffix [for]="reservationPicker"></mat-datepicker-toggle>
          <mat-datepicker #reservationPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col">
        <section class="">
          <mat-checkbox class="matin-margin" formControlName="matin">Matin</mat-checkbox>
          <mat-checkbox class="aprem-margin" formControlName="apresMidi">Après-midi</mat-checkbox>
        </section>
      </div>
    </div>
    <div class="row row-margin-top">
      <div class="col">
        <h2 mat-dialog-title>Périodicité (facultatif)</h2>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-date-range-input [rangePicker]="periodpicker" [min]="minDate">
            <input formControlName="start" matStartDate placeholder="Start date" (dateChange)="onChangePeriodicityDate()" />
            <input formControlName="end" matEndDate placeholder="End date" (dateChange)="onChangePeriodicityDate()" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="periodpicker"></mat-datepicker-toggle>
          <mat-date-range-picker #periodpicker></mat-date-range-picker>
          <mat-hint class="error" *ngIf="showErrorPeriodicity">Toutes les dates demandées ne sont pas disponible</mat-hint>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-radio-group aria-label="Select an option" formControlName="alwaysTheSameDay">
          <mat-radio-button [value]="true">Tout le temps même jours</mat-radio-button>
          <mat-radio-button [value]="false">Tous les jours entre</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </form>
</mat-dialog-content>
<div mat-dialog-actions align="center">
  <div class="row row-margin-top">
    <div class="col">
      <button mat-raised-button color="primary" [disabled]="!isFormulaireValid()" (click)="enregistrerFormulaire()" class="cy__save-form-booking">Enregistrer</button>
    </div>
    <div class="col">
      <button mat-raised-button color="warn" [disabled]="deleteButtonIsDisabled()" (click)="deleteReservation()">Supprimer</button>
    </div>
    <div class="col">
      <button mat-raised-button mat-dialog-close>Annuler</button>
    </div>
  </div>
</div>
