<div class="filters">
  <!-- Site selector -->
  <div class="filter-item">
    <mat-form-field class="full-width">
      <mat-label>Site</mat-label>
      <mat-select [(value)]="selectedSite" (selectionChange)="changeSite()">
        <mat-option [value]="site" *ngFor="let site of sites">
          {{ site.nom }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Room selector and favorite button-->
  <div class="filter-item combo">
    <!-- Room selector -->
    <div class="filter-item-room">
      <mat-form-field class="full-width">
        <mat-label>Salle</mat-label>
        <mat-select [(value)]="selectedRoom">
          <mat-option [value]="room" *ngFor="let room of salles"> {{ room.nom }} ({{ getNbSlotsPerRoom(room) }} {{ getNbSlotsPerRoom(room) > 1 ? 'places' : 'place' }}) </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Fav button -->
    <div class="filter-item-fav">
      <button
        mat-icon-button
        [matTooltip]="isCurrentRoomFavorite() ? '' : 'Mettre la salle en favori'"
        matTooltipPosition="above"
        class="room-favorite-btn"
        [disabled]="isCurrentRoomFavorite()"
        [attr.aria-disabled]="isCurrentRoomFavorite() ? 'true' : null"
        [attr.aria-label]="isCurrentRoomFavorite() ? null : 'Mettre la salle en favori'"
        [attr.title]="isCurrentRoomFavorite() ? null : 'Mettre la salle en favori'"
        (click)="registerFavorite()"
      >
        <mat-icon>favorite</mat-icon>
      </button>
    </div>
  </div>

</div>

<div class="row justify-content-center row-calendar">
  <div class="col">
    <app-main-calendar [salle]="selectedRoom"></app-main-calendar>
  </div>
</div>
