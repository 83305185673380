import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  public isLoggedIn = false;
  private destroy$ = new Subject<void>();

  constructor(private authService: SocialAuthService) {}

  ngOnInit(): void {
    this.authService.authState.pipe(takeUntil(this.destroy$)).subscribe(
      (auth: SocialUser) => {
        if (auth != null) {
          this.isLoggedIn = auth.authToken !== '';
        }
      },
      error => {
        console.error('Error during authentication: ', error);
      },
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
