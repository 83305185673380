import { Component, OnInit } from '@angular/core';
import { Observable, of, switchMap, zip } from 'rxjs';
import { Reservation } from '~/shared/models/reservation.model';
import { Salle } from '~/shared/models/salle.model';
import { Site } from '~/shared/models/site.model';
import { ReservationService } from '~/shared/services/reservation.service';
import { SalleService } from '~/shared/services/salle.service';
import { SiteService } from '~/shared/services/site.service';
import { SpinnerOverlayService } from '~/shared/services/spinner-overlay.service';

@Component({
  selector: 'app-stat-per-month',
  templateUrl: './stat-per-month.component.html',
  styleUrls: ['./stat-per-month.component.css'],
})
export class StatPerMonthComponent implements OnInit {
  start!: Date;
  end!: Date;
  reservations: Reservation[] = [];
  selectedSite?: Site = undefined;
  salles: Salle[] = [];
  sites: Site[] = [];
  averageNbMembre = 0;
  averageFillingRate = 0;

  constructor(
    private reservationService: ReservationService,
    private siteService: SiteService,
    private salleService: SalleService,
    private spinnerOverlayService: SpinnerOverlayService,
  ) {
    this.changeDateToThisMonth();
  }

  ngOnInit(): void {
    this.spinnerOverlayService.show();
    this.siteService
      .getAllSites()
      .pipe(
        switchMap(sites => {
          this.sites = sites;
          if (this.sites.length > 0) {
            this.selectedSite = this.sites.find(s => s.nom === 'Rennes');
          }
          return this.salleService.getAllSalles(this.selectedSite?.id);
        }),
        switchMap(salles => this.switchMapSalleToReservations(salles)),
      )
      .subscribe(rss => {
        this.reservations = rss.flat();
        this.computeStats();
        this.spinnerOverlayService.hide();
      });
  }

  computeStats() {
    let nbDay = 0;
    let fillingRateTotal = 0;
    let totalNbEmployee = 0;
    const totalNbSpots = this.getMaxNbOfSpot(this.salles);
    // we don't want to divide per 0
    if (totalNbSpots === 0) {
      return;
    }

    for (let date = new Date(this.start.getFullYear(), this.start.getMonth(), this.start.getDate()); date <= this.end; date.setDate(date.getDate() + 1)) {
      if (date.getDay() !== 6 && date.getDay() !== 0) {
        // saturday or sunday
        const nbTotalPerDay = this.getNbEmployeePerDay(this.reservations, date);
        totalNbEmployee += nbTotalPerDay;
        fillingRateTotal += (nbTotalPerDay * 100) / totalNbSpots;
        ++nbDay;
      }
    }

    this.averageNbMembre = totalNbEmployee / nbDay;
    this.averageFillingRate = fillingRateTotal / nbDay;
  }

  changeSite() {
    this.averageNbMembre = 0;
    this.averageFillingRate = 0;
    this.spinnerOverlayService.show();
    this.salleService
      .getAllSalles(this.selectedSite?.id)
      .pipe(switchMap(salles => this.switchMapSalleToReservations(salles)))
      .subscribe(rss => {
        this.reservations = rss.flat();
        this.computeStats();
        this.spinnerOverlayService.hide();
      });
  }

  onChangePeriodicityDate() {
    this.averageNbMembre = 0;
    this.averageFillingRate = 0;
    if (!this.start || !this.end) {
      return;
    }
    this.spinnerOverlayService.show();
    this.switchMapSalleToReservations(this.salles).subscribe(rss => {
      this.reservations = rss.flat();
      this.computeStats();
      this.spinnerOverlayService.hide();
    });
  }

  searchThisMonth() {
    this.changeDateToThisMonth();
    this.onChangePeriodicityDate();
  }

  private changeDateToThisMonth() {
    const now = new Date();
    this.start = new Date(now.getFullYear(), now.getMonth(), 1);
    this.end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  }

  private switchMapSalleToReservations(salles: Salle[]): Observable<Reservation[][]> {
    const localEnd = new Date(this.end.getTime());
    localEnd.setHours(this.end.getHours() + 12);
    this.salles = salles;
    if (salles.length === 0) {
      return of([]);
    }
    return zip(salles.map(s => this.reservationService.findReservation(this.start, localEnd, s.id)));
  }

  private getMaxNbOfSpot(salles: Salle[]): number {
    if (!this.salles) {
      return 0;
    }
    return salles.map(s => s.quota ?? s.capacite).reduce((previous, current) => previous + current, 0);
  }

  private getNbEmployeePerDay(reservations: Reservation[], date: Date): number {
    return reservations
      .filter(r => this.equalDate(r.dateReservation.date, date))
      .map(r => {
        let n = 0;
        if (r.dateReservation.matin) {
          n += 0.5;
        }
        if (r.dateReservation.apresMidi) {
          n += 0.5;
        }
        return n;
      })
      .reduce((previous, current) => previous + current, 0);
  }

  private equalDate(d1: Date, d2: Date): boolean {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
  }
}
