import { SocialAuthService } from '@abacritt/angularx-social-login';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, catchError, of, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SpinnerOverlayService } from '~/shared/services/spinner-overlay.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private socialAuthService: SocialAuthService, private router: Router, private spinnerService: SpinnerOverlayService, private snackBar: MatSnackBar) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if account is logged in and request is to the api url
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (!isApiUrl) {
      next.handle(request);
    }
    return this.socialAuthService.authState.pipe(
      switchMap(user => {
        if (user && user?.idToken) {
          request = request.clone({
            setHeaders: { Authorization: `Bearer ${user.idToken}` },
          });
        }
        return next.handle(request);
      }),
      catchError(err => {
        this.spinnerService.hide();
        if (err.status === 401 || err.status === 403) {
          this.router.navigateByUrl(`/login`);
          return of();
        }
        this.snackBar.open("Une erreur s'est produite, veuillez réessayer", undefined, {
          duration: 3000,
        });
        return throwError(() => err);
      }),
    );
  }
}
