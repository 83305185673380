import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { switchMap } from 'rxjs';
import { Personne } from '~/shared/models/personne.model';
import { Salle } from '~/shared/models/salle.model';
import { Site } from '~/shared/models/site.model';
import { PersonneService } from '~/shared/services/personne.service';
import { SalleService } from '~/shared/services/salle.service';
import { SiteService } from '~/shared/services/site.service';
import { SpinnerOverlayService } from '~/shared/services/spinner-overlay.service';

@Component({
  selector: 'app-accueil-reservation',
  templateUrl: './accueil-reservation.component.html',
  styleUrls: ['./accueil-reservation.component.scss'],
})
export class AccueilReservationComponent implements OnInit {
  salles: Salle[] = [];
  sites: Site[] = [];
  selectedRoom?: Salle = undefined;
  selectedSite?: Site = undefined;
  currentPersonne?: Personne = undefined;

  constructor(
    private salleService: SalleService,
    private siteService: SiteService,
    private personneService: PersonneService,
    private snackBar: MatSnackBar,
    private spinnerOverlayService: SpinnerOverlayService,
  ) {}

  ngOnInit(): void {
    this.spinnerOverlayService.show();
    this.personneService
      .getCurrentPersonne()
      .pipe(
        switchMap((pers: Personne) => {
          this.currentPersonne = pers;
          return this.siteService.getAllSites();
        }),
        switchMap(sites => {
          this.sites = sites;
          this.determinerSiteDeRattachement(sites, this.currentPersonne);
          return this.salleService.getSalleForCalendar(this.selectedSite?.id);
        }),
      )
      .subscribe({
        next: (salles: Salle[]) => {
          this.salles = salles;
          if (this.currentPersonne?.sallePreferee) {
            this.selectedRoom = this.salles.find(s => s.id === this.currentPersonne?.sallePreferee?.id);
          } else {
            this.selectedRoom = salles.length > 0 ? this.salles[0] : undefined;
          }
          this.spinnerOverlayService.hide();
        },
        error: (error: Error) => {
          this.spinnerOverlayService.hide();
          this.snackBar.open(error.message);
        },
      });
  }

  /**
   * Détermine le site de rattachement de la personne.
   *
   * @param personne la personne connectée à qui on veut rattacher un site
   * @param sites l'ensemble des sites disponible dans Kanoplan
   */
  private determinerSiteDeRattachement(sites: Site[], personne?: Personne) {
    if (sites.length > 0) {
      // on sélectionne arbitrairement le site de Rennes par défaut
      this.selectedSite = sites.find(s => s.nom === 'Rennes');

      // si la personne a une salle préférée, on sélectionne le site correspondant
      if (personne?.sallePreferee) {
        this.selectedSite = sites.find(s => s.id === personne?.sallePreferee?.siteId);
      }
    }
  }

  /*
   * compute the number of slots available for the given room
   */
  getNbSlotsPerRoom(salle: Salle): number {
    return salle.quota ?? salle.capacite;
  }

  changeSite() {
    this.spinnerOverlayService.show();
    this.salleService.getSalleForCalendar(this.selectedSite?.id).subscribe((salles: Salle[]) => {
      this.salles = salles;
      this.selectedRoom = salles.length > 0 ? this.salles[0] : undefined;
      this.spinnerOverlayService.hide();
    });
  }

  registerFavorite() {
    if (!this.currentPersonne || !this.currentPersonne.id || !this.selectedRoom) {
      return;
    }

    this.personneService.updateFavoriteRoom(this.currentPersonne.id, this.selectedRoom.id).subscribe((pers: Personne) => (this.currentPersonne = pers));
  }

  isCurrentRoomFavorite() {
    return this.currentPersonne?.sallePreferee?.id === this.selectedRoom?.id;
  }
}
