<div class="row justify-content-center">
  <div class="col-auto">
    <mat-form-field class="full-width">
      <mat-label>Site</mat-label>
      <mat-select [(value)]="selectedSite" (selectionChange)="changeSite()">
        <mat-option [value]="site" *ngFor="let site of sites">{{ site.nom }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-auto"></div>
  <div class="col-auto">
    <mat-form-field>
      <mat-date-range-input [rangePicker]="periodpicker">
        <input [(ngModel)]="start" matStartDate placeholder="Début" (dateChange)="onChangePeriodicityDate()" />
        <input matEndDate placeholder="Fin" [(ngModel)]="end" (dateChange)="onChangePeriodicityDate()" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="periodpicker"></mat-datepicker-toggle>
      <mat-date-range-picker #periodpicker></mat-date-range-picker>
    </mat-form-field>
  </div>
  <div class="col-auto">
    <button mat-raised-button (click)="searchThisMonth()">Ce mois ci (décembre)</button>
  </div>
</div>

<div class="row main-row title-row">
  <div class="col">
    <h1 class="kano-font">
      Statistiques du {{ start | date : 'dd/MM/YYYY' }} au
      {{ end | date : 'dd/MM/YYYY' }}
    </h1>
  </div>
</div>

<div class="row main-row">
  <div class="col">Nombre d'équipiers en moyenne: {{ averageNbMembre | number : '1.0-2' }}</div>
  <div class="col">Taux de remplissage moyen: {{ averageFillingRate | number : '1.0-2' }}%</div>
</div>

<div class="row main-row row-chart">
  <div class="col">
    <app-bar-chart [reservations]="reservations" [salles]="salles" [start]="start" [end]="end"></app-bar-chart>
  </div>
</div>
