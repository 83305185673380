import { SocialAuthService } from '@abacritt/angularx-social-login';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { switchMap, zip } from 'rxjs';
import { Reservation } from '~/shared/models/reservation.model';
import { Salle } from '~/shared/models/salle.model';
import { ReservationService } from '~/shared/services/reservation.service';
import { SalleService } from '~/shared/services/salle.service';
import { SpinnerOverlayService } from '~/shared/services/spinner-overlay.service';

@Component({
  selector: 'app-mes-reservations',
  templateUrl: './mes-reservations.component.html',
  styleUrls: ['./mes-reservations.component.css'],
})
export class MesReservationsComponent implements OnInit {
  displayedColumns: string[] = ['select', 'date', 'salle', 'action'];
  salles: Salle[] = [];
  dataSource = new MatTableDataSource<Reservation>([]);
  selection = new SelectionModel<Reservation>(true, []);

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private reservationService: ReservationService,
    private socialAuthService: SocialAuthService,
    private snackBar: MatSnackBar,
    private salleService: SalleService,
    private spinnerOverlayService: SpinnerOverlayService,
  ) {
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.findReservationGlobal();
    this.findAllSalles();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  deleteAll() {
    if (this.selection.selected.length > 0) {
      const obss = this.selection.selected.map(r => this.reservationService.deleteReservation(r.id ?? 0));
      const snackBar = this.snackBar.open(`Voulez-vous vraiment supprimer toutes ces ${this.selection.selected.length} reservations?`, 'Oui', {
        duration: 3000,
        verticalPosition: 'top',
      });
      snackBar
        .onAction()
        .pipe(
          switchMap(() => {
            this.spinnerOverlayService.show();
            return zip(obss);
          }),
        )
        .subscribe(() => {
          this.spinnerOverlayService.hide();
          this.selection.clear();
          this.findReservationGlobal();
        });
    }
  }

  findAllSalles() {
    this.salleService.getAllSalles().subscribe(salle => {
      this.salles = salle;
    });
  }

  getSalleNameFromId(id: number): string {
    const salle = this.salles.find(s => s.id === id);
    return salle?.nom ?? '';
  }

  deleteReservations(id: number) {
    const resa = this.dataSource.data.find(r => r.id === id);
    if (!resa) {
      return;
    }
    const snackBar = this.snackBar.open(`Voulez-vous vraiment supprimer la reservation du ${new Date(resa.dateReservation.date).toLocaleDateString('FR-fr')}?`, 'Oui', {
      duration: 3000,
      verticalPosition: 'top',
    });
    snackBar
      .onAction()
      .pipe(
        switchMap(() => {
          return this.reservationService.deleteReservation(id);
        }),
      )
      .subscribe(() => {
        this.findReservationGlobal();
      });
  }

  findReservationGlobal() {
    this.spinnerOverlayService.show();
    this.socialAuthService.authState
      .pipe(
        switchMap(socialUser => {
          return this.reservationService.findReservationByEmail(socialUser.email);
        }),
      )
      .subscribe(data => {
        this.dataSource.data = data.sort(this.sortByDateAsc);
        this.spinnerOverlayService.hide();
      });
  }

  sortChange(sortState: Sort) {
    if (sortState.active === 'date') {
      this.dataSource.data = this.dataSource.data.sort(sortState.direction === 'asc' ? this.sortByDateAsc : this.sortByDateDesc);
    } else if (sortState.active === 'salle') {
      this.dataSource.data = this.dataSource.data.sort(sortState.direction === 'asc' ? this.sortBySalleAsc.bind(this) : this.sortBySalleDesc.bind(this));
    }
  }

  private sortByDateAsc(r1: Reservation, r2: Reservation): number {
    const dateR1 = new Date(r1.dateReservation?.date);
    const dateR2 = new Date(r2.dateReservation?.date);
    return (dateR1.getTime() ?? 0) - (dateR2.getTime() ?? 0);
  }

  private sortByDateDesc(r1: Reservation, r2: Reservation): number {
    const dateR1 = new Date(r1.dateReservation?.date);
    const dateR2 = new Date(r2.dateReservation?.date);
    return (dateR2.getTime() ?? 0) - (dateR1.getTime() ?? 0);
  }

  private sortBySalleAsc(r1: Reservation, r2: Reservation): number {
    const r1S = this.getSalleNameFromId(r1.salleId);
    const r2s = this.getSalleNameFromId(r2.salleId);
    return r1S < r2s ? -1 : r1S > r2s ? 1 : 0;
  }

  private sortBySalleDesc(r1: Reservation, r2: Reservation): number {
    const r1S = this.getSalleNameFromId(r1.salleId);
    const r2s = this.getSalleNameFromId(r2.salleId);
    return r2s < r1S ? -1 : r2s > r1S ? 1 : 0;
  }
}
