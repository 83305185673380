<mat-card>
  <mat-card-header class="justify-content-center">
    <mat-card-title>Login</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-card-actions class="justify-content-center">
      <asl-google-signin-button type="standard" size="medium"></asl-google-signin-button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
