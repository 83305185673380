import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Personne } from '../models/personne.model';

@Injectable({
  providedIn: 'root',
})
export class PersonneService {
  constructor(private http: HttpClient, private socialAuthService: SocialAuthService) {}

  getPersonneWithEmail(email: string): Observable<Personne[]> {
    const params = new HttpParams().append('email', email);
    return this.http.get<Personne[]>(environment.apiUrl + '/personne/find', {
      params,
    });
  }

  createPersonne(personne: Personne): Observable<Personne> {
    return this.http.post<Personne>(environment.apiUrl + '/personne', personne);
  }

  createPersonneFromSocialLogin(): Observable<Personne> {
    return this.socialAuthService.authState.pipe(
      switchMap((socialUser: SocialUser) => {
        return this.createPersonne({
          nom: socialUser?.lastName,
          prenom: socialUser?.firstName,
          email: socialUser?.email,
        });
      }),
    );
  }

  updateFavoriteRoom(idpersonne: number, roomId: number): Observable<Personne> {
    return this.http.patch<Personne>(`${environment.apiUrl}/personne/${idpersonne}`, { sallePrefereeId: roomId });
  }

  getCurrentPersonne(): Observable<Personne> {
    return this.socialAuthService.authState.pipe(
      switchMap((socialUser: SocialUser) => {
        return this.getPersonneWithEmail(socialUser?.email);
      }),
      switchMap((pers: Personne[]) => {
        if (pers.length === 0) {
          return this.createPersonneFromSocialLogin();
        } else if (pers.length === 1) {
          return of(pers[0]);
        } else {
          throw new Error(`Il y a plusieurs personnes associées à cet email (${pers[0].email}) dans Kanoplan`);
        }
      }),
    );
  }
}
