import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as removeAccents from 'remove-accents';
import { Reservation } from '~/shared/models/reservation.model';
import { SalleService } from '~/shared/services/salle.service';
import { FormulaireReservationDialogData } from './formulaire-reservation.data';

@Component({
  selector: 'app-formulaire-reservation',
  templateUrl: './formulaire-reservation.component.html',
  styleUrls: ['./formulaire-reservation.component.css'],
})
export class FormulaireReservationComponent implements OnInit {
  formulaireReservation = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    firstName: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', Validators.required),
    room: new UntypedFormControl('', Validators.required),
    date: new UntypedFormControl('', Validators.required),
    matin: new UntypedFormControl(true),
    apresMidi: new UntypedFormControl(true),
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
    alwaysTheSameDay: new UntypedFormControl(true),
    reservationForMe: new UntypedFormControl(true),
  });
  minDate: Date = new Date();
  minDate0: Date = new Date();
  showErrorPeriodicity = false;
  user: SocialUser = new SocialUser();
  nameWithoutAccentAndSpace = '';
  firstNameWithoutAccent = '';

  constructor(
    public dialogRef: MatDialogRef<FormulaireReservationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormulaireReservationDialogData,
    private socialAuthService: SocialAuthService,
    private salleService: SalleService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.socialAuthService.authState.subscribe(user => {
      this.user = user;
      this.formulaireReservation.get('name')?.setValue(user.lastName);
      this.formulaireReservation.get('name')?.disable();
      this.formulaireReservation.get('firstName')?.setValue(user.firstName);
      this.formulaireReservation.get('firstName')?.disable();
      this.formulaireReservation.get('email')?.setValue(user.email);
      this.formulaireReservation.get('email')?.disable();
    });

    this.formulaireReservation.get('room')?.setValue(this.data.salle.nom);
    this.formulaireReservation.get('room')?.disable();

    if (this.data.isNewReservation) {
      // set date of the day
      this.formulaireReservation.get('date')?.setValue(this.data.date);
      this.formulaireReservation.get('start')?.setValue(this.data.date);
      this.minDate = this.formulaireReservation.get('start')?.value ?? this.minDate0;

      if (this.data.reservation) {
        // disable choice because it's mandatory to create reservation for another personne
        this.formulaireReservation.get('reservationForMe')?.setValue(false);
        this.formulaireReservation.get('reservationForMe')?.disable();

        this.formulaireReservation.get('name')?.enable();
        this.formulaireReservation.get('name')?.setValue('');
        this.formulaireReservation.get('firstName')?.enable();
        this.formulaireReservation.get('firstName')?.setValue('');
        this.formulaireReservation.get('email')?.enable();
        this.formulaireReservation.get('email')?.setValue('');
      }
    } else {
      // disable choice because it's mandatory to update reservation
      this.formulaireReservation.get('reservationForMe')?.setValue(true);
      this.formulaireReservation.get('reservationForMe')?.disable();

      this.formulaireReservation.get('date')?.setValue(this.data.reservation?.dateReservation.date);
      this.formulaireReservation.get('start')?.setValue(this.data.reservation?.dateReservation.date);
      this.formulaireReservation.get('matin')?.setValue(this.data.reservation?.dateReservation.matin);
      this.formulaireReservation.get('apresMidi')?.setValue(this.data.reservation?.dateReservation.apresMidi);
      this.minDate = this.formulaireReservation.get('start')?.value ?? this.minDate0;
    }
  }

  enregistrerFormulaire(): void {
    let reservation = this.formulaireReservation.value;
    reservation = this.completeReservationWithMissingFields(reservation);

    const result = {
      reservation: reservation,
      toDelete: false,
    };

    const index = this.data.reservations.findIndex(el => el.personne.email == reservation.email);
    const retour = this.checkIfRoomIsFullForReservation(reservation, this.data.reservations[index]);

    if (!retour) {
      this.dialogRef.close(result);
    }
  }

  checkIfRoomIsFullForReservation(newReservation: any, reservation: Reservation): boolean {
    let retour;
    const roomIsFull = this.salleService.isTheRoomFull(new Date(newReservation.date), this.data.reservations, this.data.salle);
    const slots = this.salleService.getNbSlots(new Date(newReservation.date), this.data.reservations, this.data.salle);

    if (!reservation) {
      const nbSlotAM = slots[0];
      const nbSlotTotal = slots[2];
      const nbSlotPM = slots[1];
      // Pour éviter de réserver + places qu'il n'y a dans les salles avec les 1/2 journées
      if (nbSlotAM >= nbSlotTotal && nbSlotPM >= nbSlotTotal) {
        this.snackBar.open('Toutes les places sont prises, pas de réservation ,possible.\n', undefined, { duration: 2000 });
      }
      if (roomIsFull.roomIsFullAM && newReservation.matin) {
        retour = true;
        this.snackBar.open('Il ne reste plus de place dans cette salle le matin, à la date voulue.\n', undefined, { duration: 2000 });
      } else if (roomIsFull.roomIsFullPM && newReservation.apresMidi) {
        retour = true;
        this.snackBar.open("Il ne reste plus de place dans cette salle l'après-midi, à la date voulue.\n", undefined, { duration: 2000 });
      } else {
        retour = false;
      }
    } else {
      if (roomIsFull.roomIsFullAM && newReservation.matin && !reservation.dateReservation.matin) {
        retour = true;
        this.snackBar.open('Il ne reste plus de place dans cette salle le matin, à la date voulue.\n', undefined, { duration: 2000 });
      } else if (roomIsFull.roomIsFullPM && newReservation.apresMidi && !reservation.dateReservation.apresMidi) {
        retour = true;
        this.snackBar.open("Il ne reste plus de place dans cette salle l'après-midi, à la date voulue.\n", undefined, { duration: 2000 });
      } else {
        retour = false;
      }
    }

    return retour;
  }

  completeReservationWithMissingFields(reservation: any) {
    if (!reservation.name) {
      reservation.name = this.formulaireReservation.get('name')?.value;
    }

    if (!reservation.firstName) {
      reservation.firstName = this.formulaireReservation.get('firstName')?.value;
    }

    if (!reservation.email) {
      reservation.email = this.formulaireReservation.get('email')?.value;
    }
    return reservation;
  }

  deleteReservation(): void {
    let reservation = this.formulaireReservation.value;
    reservation = this.completeReservationWithMissingFields(reservation);

    const result = {
      reservation: reservation,
      toDelete: true,
    };
    this.dialogRef.close(result);
  }

  deleteButtonIsDisabled(): boolean {
    return this.data.isNewReservation;
  }

  onChangeDate(): void {
    this.formulaireReservation.get('start')?.setValue(this.formulaireReservation.get('date')?.value);
    this.minDate = this.formulaireReservation.get('start')?.value;
  }

  onChangeReservationForMe(): void {
    if (this.formulaireReservation.get('reservationForMe')?.value) {
      this.formulaireReservation.get('name')?.disable();
      this.formulaireReservation.get('name')?.setValue(this.user.lastName);
      this.formulaireReservation.get('firstName')?.disable();
      this.formulaireReservation.get('firstName')?.setValue(this.user.firstName);
      this.formulaireReservation.get('email')?.disable();
      this.formulaireReservation.get('email')?.setValue(this.user.email);
    } else {
      this.formulaireReservation.get('name')?.enable();
      this.formulaireReservation.get('name')?.setValue('');
      this.formulaireReservation.get('firstName')?.enable();
      this.formulaireReservation.get('firstName')?.setValue('');
      this.formulaireReservation.get('email')?.enable();
      this.formulaireReservation.get('email')?.setValue('');
    }
  }

  isTimeSelected(): boolean {
    return this.formulaireReservation.get('matin')?.value || this.formulaireReservation.get('apresMidi')?.value;
  }

  isFormulaireValid() {
    return this.formulaireReservation.valid && this.isTimeSelected();
  }

  onChangeFirstName() {
    this.firstNameWithoutAccent = removeAccents(this.formulaireReservation.get('firstName')?.value).toLowerCase();
    this.buildEmailWithoutAccentAndCase();
  }

  onChangeName() {
    const nameWithoutAccent = removeAccents(this.formulaireReservation.get('name')?.value).toLowerCase();
    this.nameWithoutAccentAndSpace = nameWithoutAccent.replace(/ /g, '-');

    this.formulaireReservation.get('name')?.setValue(this.formulaireReservation.get('name')?.value.toUpperCase());

    this.buildEmailWithoutAccentAndCase();
  }

  buildEmailWithoutAccentAndCase() {
    const email = `${this.firstNameWithoutAccent}.${this.nameWithoutAccentAndSpace}@kanoma.fr`;
    this.formulaireReservation.get('email')?.setValue(email);
  }

  onChangePeriodicityDate() {
    this.showErrorPeriodicity = false;
    const start: Date = this.formulaireReservation.get('start')?.value;
    const endDate: Date = this.formulaireReservation.get('end')?.value;

    for (const date = new Date(start); date <= endDate; date.setDate(date.getDate() + 1)) {
      if (!this.dateFilter(date)) {
        this.showErrorPeriodicity = true;
        break;
      }
    }
  }

  dateFilter = (date: Date | null): boolean => {
    if (date == null) {
      return false;
    }

    if (this.data.reservation?.dateReservation.date && new Date(this.data.reservation?.dateReservation.date).toISOString() == date.toISOString()) {
      return true;
    }

    const roomIsFull = this.salleService.isTheRoomFull(date, this.data.reservations, this.data.salle);

    return !(roomIsFull.roomIsFullAM && roomIsFull.roomIsFullPM);
  };
}
