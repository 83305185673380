import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DateSelectArg, EventApi, EventClickArg, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Observable, of, switchMap } from 'rxjs';
import { Reservation } from '~/shared/models/reservation.model';
import { Salle } from '~/shared/models/salle.model';
import { ReservationService } from '~/shared/services/reservation.service';
import { SalleService } from '~/shared/services/salle.service';
import { SpinnerOverlayService } from '~/shared/services/spinner-overlay.service';
import { FormulaireReservationComponent } from '../formulaire-reservation/formulaire-reservation.component';

@Component({
  selector: 'app-main-calendar',
  templateUrl: './main-calendar.component.html',
  styleUrls: ['./main-calendar.component.css'],
})
export class MainCalendarComponent implements OnChanges, OnInit {
  @Input() salle?: Salle;
  // references the #calendar in the template
  @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;

  //dateSelected?: Date = undefined;
  reservations: Reservation[] = [];
  currentEvents: EventApi[] = [];
  user?: SocialUser;

  // calendar option
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, interactionPlugin],
    dayMaxEvents: true, // allow "more" link when too many events
    weekends: false,
    editable: true,
    selectable: true,
    selectMirror: true,
    locale: 'fr',
    customButtons: {
      addEventButton: {
        text: 'Réserver',
        click: () => this.displayReservationForm(),
      },
      next: {
        click: this.handleNext.bind(this),
      },
      prev: {
        click: this.handlePrev.bind(this),
      },
      today: {
        text: "Aujourd'hui",
        click: this.handleToday.bind(this),
      },
    },
    headerToolbar: {
      left: '',
      center: 'title',
      right: 'addEventButton today prev,next', //dayGridMonth,timeGridWeek,timeGridDay'
    },
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };

  constructor(
    public dialog: MatDialog,
    private reservationService: ReservationService,
    private salleService: SalleService,
    private socialAuthService: SocialAuthService,
    private spinnerOverlayService: SpinnerOverlayService,
    private snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.socialAuthService.authState.subscribe(user => {
      this.user = user;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['salle']) {
      this.getMonthBooking();
    }
  }

  private getMonthBooking() {
    if (!this.salle?.id) {
      return;
    }
    this.spinnerOverlayService.show();
    this.reservationService.findReservation(this.getMonthStartDate(), this.getMonthEndDate(), this.salle.id).subscribe(reservs => {
      this.reservations = reservs;
      this.displayReservations();
      this.spinnerOverlayService.hide();
      this.calendarComponent?.getApi().render();
    });
  }

  private getMonthStartDate() {
    let start = this.calendarComponent?.getApi().view.activeStart;
    if (!start) {
      const now = new Date();
      // we default to the last week of the previous month
      start = new Date(now.getFullYear(), now.getMonth(), 1);
      start.setDate(start.getDate() - 7);
    }
    return start;
  }

  private getMonthEndDate() {
    let end = this.calendarComponent?.getApi().view.activeEnd;
    if (!end) {
      const now = new Date();
      // we default to the first week of the next month
      end = new Date(now.getFullYear(), now.getMonth(), 28);
      end.setDate(end.getDate() + 10);
    }
    return end;
  }

  private displayReservations() {
    this.calendarComponent?.getApi().removeAllEvents();
    for (const r of this.reservations) {
      const isAllDay = r.dateReservation?.matin && r.dateReservation?.apresMidi;
      const reserDate = new Date(r.dateReservation?.date);
      let title = r.personne?.nom + ' ' + r.personne?.prenom;
      if (!isAllDay && r.dateReservation?.matin) {
        title += ' - matin';
      } else if (!isAllDay && r.dateReservation?.apresMidi) {
        title += ' - après midi';
      }
      const event: EventInput = {
        title: title,
        start: reserDate,
        end: reserDate,
        classNames: !isAllDay ? ['halfday'] : [],
        allDay: true, // juste to have a nice display,
        editable: false,
        id: r.id + '',
      };

      this.calendarComponent?.getApi().addEvent(event);
    }
    this.updateCellClassName();
  }

  private updateCellClassName() {
    const start = this.getMonthStartDate();
    start.setHours(12); // to avoid any iso date issue
    const end = this.getMonthEndDate();
    for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
      const className = this.isCellNotEditable(date) ? 'disabled-cell' : '';
      const dateString = date.toISOString().split('T')[0];
      const elem = document.querySelector(`.fc-day[data-date="${dateString}"]`);
      if (elem && className) {
        elem.classList.add(className);
      } else if (elem) {
        elem.classList.remove('disabled-cell');
      }
    }
  }

  private isCellNotEditable(date: Date): boolean {
    const now = new Date();
    date = new Date(date); // needed to have all the date function available
    if (date < new Date(now.getFullYear(), now.getMonth(), now.getDate())) {
      return true;
    }

    const roomIsFull = this.salleService.isTheRoomFull(date, this.reservations, this.salle);

    return roomIsFull.roomIsFullAM && roomIsFull.roomIsFullPM;
  }

  public handleNext() {
    this.calendarComponent?.getApi().next();
    this.getMonthBooking();
  }

  public handlePrev() {
    this.calendarComponent?.getApi().prev();
    this.getMonthBooking();
  }

  public handleToday() {
    this.calendarComponent?.getApi().today();
    this.getMonthBooking();
  }

  private handleDateSelect(selectInfo: DateSelectArg) {
    if (!this.salle?.id || !this.user?.email) {
      return;
    }

    const srtDate = new Date(selectInfo.startStr);

    if (this.isCellNotEditable(srtDate)) {
      if (srtDate >= new Date()) {
        this.snackBar.open(
          'Il ne reste plus de place dans cette salle, à la date voulue.\nEssayez de changer de salle ou de discuter avec les personnes déjà présente',
          undefined,
          {
            duration: 2000,
          },
        );
      }
      return;
    }

    // check if there is another reservation
    const dateSelected = new Date(selectInfo.startStr);
    this.reservationService.findReservationByEmailAndByDate(dateSelected, this.user.email).subscribe(reservations => {
      if (reservations && reservations.length > 0) {
        this.displayReservationForm(reservations[0], dateSelected);
      } else {
        this.displayReservationForm(undefined, dateSelected);
      }
    });
  }

  private handleEventClick(eventClick: EventClickArg) {
    const idEvent = parseInt(eventClick.event.id);
    const reserv = this.reservations.find(r => r.id === idEvent);
    const now = new Date();
    now.setDate(now.getDate() - 1);
    if (!reserv?.dateReservation.date || reserv?.dateReservation.date <= now || (reserv.personne.email !== this.user?.email && reserv.createur?.email !== this.user?.email)) {
      return;
    }

    this.displayReservationForm(reserv, undefined, false);
  }

  private handleEvents(events: EventApi[]) {
    this.currentEvents = events;
    this.changeDetector.detectChanges();
  }

  private displayReservationForm(reservation?: Reservation, dateSelected?: Date, isNewReservation = true) {
    const dialogRef = this.dialog.open(FormulaireReservationComponent, {
      data: {
        salle: this.salle,
        date: dateSelected,
        isNewReservation: isNewReservation,
        reservation: reservation,
        reservations: this.reservations,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap(result => {
          if (!result) {
            return of();
          }

          let retour: Observable<unknown>;

          // build common object of reservation
          const ecran = true;
          const salleId = this.salle?.id ?? 0;
          const dateReservation = {
            date: result.reservation.date,
            matin: result.reservation.matin,
            apresMidi: result.reservation.apresMidi,
          };

          // if no reservation --> POST
          if (!reservation) {
            reservation = {
              salleId: salleId,
              ecran: ecran,
              dateReservation: dateReservation,
              personne: {
                prenom: result.reservation.reservationForMe ? this.user?.firstName : result.reservation.firstName,
                nom: result.reservation.reservationForMe ? this.user?.name : result.reservation.name,
                email: result.reservation.reservationForMe ? this.user?.email : result.reservation.email,
              },
            };
            retour = this.reservationService.createReservation(reservation);
          } else {
            // if no reservation --> verify identity
            if (this.isSameEmail(reservation.personne.email, result.reservation.email) || this.isSameEmail(reservation.createur?.email, result.reservation.email)) {
              // PUT or DELETE
              reservation.dateReservation = dateReservation;
              retour = result.toDelete ? this.reservationService.deleteReservation(reservation.id ?? 0) : this.reservationService.updateReservation(reservation);
            } else {
              // POST
              reservation = {
                salleId: salleId,
                ecran: ecran,
                dateReservation: dateReservation,
                personne: {
                  prenom: result.reservation.firstName,
                  nom: result.reservation.name,
                  email: result.reservation.email,
                },
              };
              retour = this.reservationService.createReservation(reservation);
            }
          }

          // build periodicite
          // TODO --> à revoir
          if (result.reservation.start && result.reservation.end) {
            reservation.periodicite = {
              dateDebut: result.reservation.start,
              dateFin: result.reservation.end,
              memeJourSemaine: result.reservation.alwaysTheSameDay,
              joursEntreDateDebutDateFin: !result.reservation.alwaysTheSameDay,
            };
          }

          return retour;
        }),
      )
      .subscribe(() => this.getMonthBooking());
  }

  isSameEmail(emailReservation = '', emailResult: string): boolean {
    return emailReservation.localeCompare(emailResult) == 0;
  }
}
