import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Reservation } from '../models/reservation.model';

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  constructor(private http: HttpClient) {}

  createReservation(reservation: Reservation): Observable<Reservation> {
    return this.http.post<Reservation>(environment.apiUrl + '/reservations', reservation);
  }

  updateReservation(reservation: Reservation): Observable<Reservation> {
    return this.http.put<Reservation>(`${environment.apiUrl}/reservations/${reservation.id}`, reservation);
  }

  deleteReservation(id: number): Observable<Response> {
    return this.http.delete<Response>(`${environment.apiUrl}/reservations/${id}`);
  }

  findReservation(start: Date, end: Date, salleId: number): Observable<Reservation[]> {
    let params = new HttpParams();
    params = params.append('salleId', salleId);
    params = params.append('dateDebut', start.toISOString());
    params = params.append('dateFin', end.toISOString());
    return this.http.get<Reservation[]>(environment.apiUrl + '/reservations/find', {
      params: params,
    });
  }

  findReservationByEmail(email: string): Observable<Reservation[]> {
    let params = new HttpParams();
    params = params.append('email', email);
    return this.http.get<Reservation[]>(environment.apiUrl + '/reservations/find', {
      params: params,
    });
  }

  findReservationByEmailAndByDate(start: Date, email: string): Observable<Reservation[]> {
    let params = new HttpParams();

    params = params.append('dateDebut', start.toISOString());
    params = params.append('email', email);

    return this.http.get<Reservation[]>(environment.apiUrl + '/reservations/find', {
      params: params,
    });
  }
}
