<div class="container-xl">
  <div class="table">
    <div class="table-wraper">
      <div class="table-title">
        <div class="row">
          <div class="col-sm-6">
            <h1>Mes Réservations</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            <button mat-raised-button (click)="deleteAll()" [disabled]="this.selection.isEmpty()">Supprimer les reservations selectionnées</button>
          </div>
        </div>
        <div class="row">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table" matSort (matSortChange)="sortChange($event)">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"> </mat-checkbox>
              </td>
            </ng-container>
            <!-- Date Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
              <td mat-cell *matCellDef="let element">
                Le
                {{ element.dateReservation.date | date : 'dd/MM/YYYY' }}
                <span *ngIf="element.dateReservation.matin && !element.dateReservation.apresMidi">- matin</span>
                <span *ngIf="element.dateReservation.apresMidi && !element.dateReservation.matin">- aprés midi</span>
              </td>
            </ng-container>

            <!-- Salle Column -->
            <ng-container matColumnDef="salle">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Salle</th>
              <td mat-cell *matCellDef="let element">
                {{ getSalleNameFromId(element.salleId) }}
              </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let element" class="action-link">
                <a class="delete" (click)="deleteReservations(element.id)"><i class="material-icons">&#xE872;</i></a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
