import { NativeDateAdapter } from '@angular/material/core';

/** Adapts the native JS Date for use with cdk-based components that work with dates. */
export class CustomDateAdapter extends NativeDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  override format(date: Date, displayFormat: Object): string {
    let result = date.toDateString();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    switch (displayFormat) {
      case 'DD/MM/YYYY':
        // Return the format as per your requirement
        result = `${day}/${month}/${year}`;
        break;
      default:
      case 'MMM YYYY':
        // Return the format as per your requirement
        result = `${month}/${year}`;
        break;
    }
    return result;
  }

  override parse(value: string): Date | null {
    const parts = value.split('/');
    if (parts.length === 3) {
      return new Date(+parts[2], +parts[1] - 1, +parts[0]);
    }
    return null;
  }
}
