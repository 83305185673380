import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Reservation } from '../models/reservation.model';
import { Salle } from '../models/salle.model';

@Injectable({
  providedIn: 'root',
})
export class SalleService {
  constructor(private http: HttpClient) {}

  getAllSalles(siteId?: number): Observable<Salle[]> {
    let params = new HttpParams();
    if (siteId) {
      params = params.append('siteId', siteId);
    }
    return this.http.get<Salle[]>(environment.apiUrl + '/salle/find', {
      params: params,
    });
  }

  getSalleForCalendar(siteId?: number): Observable<Salle[]> {
    return this.getAllSalles(siteId).pipe(map(salles => salles.filter(s => s.disponibilite)));
  }

  /**
   *
   * @param date
   * @param reservations
   * @param salle
   * @returns
   */
  getNbSlots(date: Date | undefined, reservations: Reservation[], salle?: Salle): number[] {
    const personneOnTheDateList = reservations.filter(r => {
      const dR = new Date(r.dateReservation?.date);
      return r.salleId === salle?.id && dR.getFullYear() === date?.getFullYear() && dR.getMonth() === date.getMonth() && dR.getDate() === date.getDate();
    });

    if (personneOnTheDateList.length >= 1) {
      const nbPersonneOnTheDateAM = personneOnTheDateList.map(personne => (personne.dateReservation?.matin ? (1 as number) : (0 as number))).reduce((a, b) => a + b);

      const nbPersonneOnTheDatePM = personneOnTheDateList.map(personne => (personne.dateReservation?.apresMidi ? (1 as number) : (0 as number))).reduce((a, b) => a + b);

      const nbSlotsPerRoom = salle?.quota ?? salle?.capacite ?? 0;

      return [nbPersonneOnTheDateAM, nbPersonneOnTheDatePM, nbSlotsPerRoom];
    } else {
      return [];
    }
  }

  isTheRoomFull(
    date: Date | undefined,
    reservations: Reservation[],
    salle?: Salle,
  ): {
    roomIsFullAM: boolean;
    roomIsFullPM: boolean;
  } {
    const iterator = this.getNbSlots(date, reservations, salle);

    if (iterator.length > 1) {
      const nbSlotAM = iterator[0];
      const nbSlotPM = iterator[1];
      const nbSlotTotal = iterator[2];
      return {
        roomIsFullAM: nbSlotAM >= nbSlotTotal,
        roomIsFullPM: nbSlotPM >= nbSlotTotal,
      };
    } else {
      return {
        roomIsFullAM: false,
        roomIsFullPM: false,
      };
    }
  }
}
