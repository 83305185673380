import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  constructor(private router: Router, private socialAuthService: SocialAuthService) {}

  ngOnInit() {
    this.socialAuthService.authState?.subscribe(() => this.router.navigate(['accueil']));
  }
}
