import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FullCalendarModule } from '@fullcalendar/angular';
import { FormulaireReservationComponent } from './components/formulaire-reservation/formulaire-reservation.component';
import { MainCalendarComponent } from './components/main-calendar/main-calendar.component';
import { AccueilReservationComponent } from './pages/accueil-reservation/accueil-reservation.component'; // a plugin!
import { MesReservationsComponent } from './pages/mes-reservations/mes-reservations.component';
import { ReservationsRoutingModule } from './reservations-routing.module';

@NgModule({
  declarations: [MainCalendarComponent, AccueilReservationComponent, FormulaireReservationComponent, MesReservationsComponent],
  imports: [
    ReservationsRoutingModule,
    FullCalendarModule,
    MatSelectModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatButtonModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class ReservationsModule {}
